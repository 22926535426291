import * as React from 'react';
import styles from './ScriptUploadModal.module.scss';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { AppContext } from 'shared/context/AppContext';
import { useEffect, useRef } from 'react';
import { Modal } from 'components/modal';
import { Checkbox } from 'components';
import { ANAL_MODEL } from '../../constants';
import { Typography } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';

import script_upload_cancel from '../../../public/common/script_upload_cancel.svg';
import { closeModal, openModal } from 'modules/modal/modalReducer';
import { ModalTypes } from 'components/modal';
import { RootState } from 'modules';
import { LabelDropdown } from 'components/dropdown';
import { getUserResourceUsage } from '../../../modules/user/UserReducer';
import { useSelector } from 'react-redux';
import { AiResourceQuota } from '../../../modules/user/User';
import Dropzone from 'react-dropzone';
import fakeImage from '../../../public/fake/empty_file.svg';

export type ScriptUploadModalProps = {
  projectNo: number;
  onConfirm?: (scriptNo: number) => void;
};

export type FileEpisode = {
  file: File;
  episode?: string;
};

export default function ScriptUploadModal({ projectNo, onConfirm }: ScriptUploadModalProps) {
  const dispatch = useAppDispatch();
  const appContext = React.useContext(AppContext);
  const { project } = useAppSelector((state: RootState) => state.project);
  const [files, setFiles] = React.useState<FileEpisode[]>([]);
  const inputFile = useRef<any>(null);
  const [selectedModel, setSelectedModel] = React.useState<string>('1');
  const { resourceUsage } = useSelector((state: RootState) => state.user);

  const maxEP = Number(project?.data?.episode || 1);

  useEffect(() => {
    dispatch(getUserResourceUsage());
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFiles(files.concat({
        file: e.target.files[0],
      }));
    }

  };

  const handleEpiNameChange = (file: File, value: string) => {
    const newFiles = files.map((f) => {
      if (f.file === file) {
        return { ...f, episode: value };
      }
      return f;
    });
    setFiles(newFiles);

  };

  const handleUploadSuccess = async (scriptNo: number) => {
    if (!projectNo) return;
    // await tagAllScript(projectNo, scriptNo);
    onConfirm?.(scriptNo);
    dispatch(closeModal(ModalTypes.ScriptUpload));
  };

  const getFileExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf('.');

    // 점이 없거나 점이 파일 이름의 첫 번째 문자에 있을 경우 확장자가 없다고 간주
    if (lastDotIndex > 0 && lastDotIndex < filename.length - 1) {
      return filename.slice(lastDotIndex + 1);
    }

    return '';
  };

  const handleUploadFile = (filelist: File[]) => {
    if (!filelist?.length) return;

    const invalidFiles = filelist.filter((file) => {
      const type = getFileExtension(file.name);
      if (type !== 'hwp' && type !== 'pdf') {
        return true;
      }
      return false;
    });

    if (invalidFiles.length) {
      dispatch(
        openModal({
          type: ModalTypes.Confirm,
          props: { text: '지원하지 않는 파일 형식입니다.', confirmBtnText: '확인', autoClose: true },
        })
      );
      return;
    }

    setFiles([...files, ...filelist.map((file) => ({ file }))]);
  };

  const handleUploadAndAnal = async () => {
    if (!appContext.user || !projectNo) return;

    console.log(selectedModel);

    const aiResourceUsage = AiResourceQuota.fromQuotaUsage(resourceUsage.data);

    let errorMsg = '';
    // chatgpt 분석일 경우 토큰이 부족하면 에러 메시지 출력
    if (aiResourceUsage.percent >= 100 && selectedModel === '2') errorMsg = '토큰이 부족 합니다.';
    if (!files) errorMsg = '파일을 선택해주세요';
    files.forEach((fileEpi) => {
      if (!fileEpi.episode) errorMsg = '에피소드를 선택해주세요';
    });
    if (files.length > 10) {
      errorMsg = '파일은 최대 10개까지 업로드 가능 합니다.';
    }

    if (errorMsg) {
      dispatch(
        openModal({
          type: ModalTypes.Confirm,
          props: { text: errorMsg, confirmBtnText: '확인', autoClose: true },
        })
      );
      return;
    }

    if (!files) return;

    dispatch(
      openModal({
        type: ModalTypes.ScriptProcessing,
        props: {
          projectNo,
          files: files,
          duration: 100,
          model: selectedModel,
          onComplete: handleUploadSuccess,
        },
        overlayOptions: {
          disableOverlayClick: true,
        },
      })
    );
  };

  const fileListComponent = (file: File) => {
    return (
      <div className="d-flex flex-row gap-2 align-items-center justify-content-center mb-2">
        <InputGroup>
          <FormControl
            className={styles.input}
            style={{
              background: `var(--gray-10)`,
              borderRight: 'none',
            }}
            disabled={true}
            value={file ? file.name : ''}
          />
          <Button
            style={{
              background: `var(--gray-10)`,
              border: `1px solid var(--gray-30)`,
              borderLeft: 'none',
              height: `var(--f32)`,
            }}
            onClick={() => {
              setFiles(files.filter((f) => f.file !== file));
            }}
          >
            <div className={styles.btn_upload_cancel}>
              <img src={script_upload_cancel} alt="script_upload_cancel"/>
            </div>
          </Button>
        </InputGroup>
        <div>
          <LabelDropdown
            inputStyle={{ fontSize: 12 }}
            items={Array.from(Array(maxEP)).map((_, index) => ({ id: index + 1, text: String(index + 1) }))}
            content="EP"
            onSelected={(val) => handleEpiNameChange(file, String(val?.id || ''))}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal.Container size="wd" style={{ borderRadius: `var(--f4)`, paddingTop: `var(--f20)`, width: `var(--f522)` }}>
      <Dropzone onDrop={(acceptedFiles: File[]) => handleUploadFile(acceptedFiles)} noClick multiple>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div {...getRootProps()} className={styles.dropzone_style}>
              <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                <input {...getInputProps()} />
                <img src={fakeImage} />
                <Typography variant="f24-166--96" className="font-medium">
                  파일을 여기 끌어다 놓거나
                </Typography>
                {
                  isDragActive ? (
                    <Typography variant="f16-166--64" className="font-medium text-blue-gray-300">
                      파일을 드롭 하세요.
                    </Typography>
                  ) : (
                    <Typography variant="f16-166--64" className="font-medium text-blue-gray-300">
                      파일 업로드 버튼을 사용하세요.
                    </Typography>
                  )
                }
              </div>
          </div>
        )}
      </Dropzone>

      <div>
        {files.map(f => fileListComponent(f.file))}
      </div>

      <Button
        className={styles.button}
        style={{
          background: `var(--gray-90)`,
          border: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          if (inputFile.current != null) {
            inputFile.current.click();
          }
        }}>
        <Typography variant="f12-166--48">파일 업로드</Typography>
        <input hidden ref={inputFile} type="file" accept=".hwp, .pdf" onChange={handleFileChange} />
      </Button>

      <div className="text-left mt-2">
        <Modal.Label text="분석 모델 선택" style={{ fontSize: `var(--f10)`, color: ` var(--gray-50)` }} />
        {ANAL_MODEL.map((item, index) => (
          <div key={index} className="items-center justify-between mt-3">
            <Typography className="text-blue-gray-300">{item.text}</Typography>
            <Checkbox
              id={`checkbox-${item.text}-${index}`}
              checked={selectedModel === item.value}
              onChange={() => setSelectedModel(item.value)}
            />
          </div>
        ))}
      </div>

      <div className={styles.control_btn_group}>
        <Modal.Button
          text="취소"
          style={{ background: 'none', color: `var(--gray-50)`, padding: `var(--f10)`, fontWeight: 500 }}
        />
        <Button variant="none" className="bg-white fs-2.5 p-2.5 border-0" onClick={handleUploadAndAnal}>
          확인
        </Button>
      </div>
    </Modal.Container>
  );
}
