import SvAPI, { serverUrl } from '../../util/SvAPI';
import CommonResponse from '../common/CommonResponse';
import { get, post } from '../common/HttpCaller';

export interface ScriptUploadItem {
  file: File;
  episode: string;
}

export interface Script {
  id: number;
  episode: string;
  fileName: string;
  projectNo: number;
  registerNo: number;
  scriptNo: number;
}

interface ScriptUploadResult {
  scriptNo: number;
}

export default class ScriptService {
  getScripts = async (projectNo: number): Promise<Script[]> => {
    const response = await get<undefined, Script[]>(`${serverUrl}/projects/${projectNo}/scripts`, {});
    return response!!;
  };

  addScript = async (projectNo: number, scriptUploadItem: ScriptUploadItem): Promise<ScriptUploadResult> => {
    const formData = new FormData();
    formData.append('file', scriptUploadItem.file);
    formData.append('episode', scriptUploadItem.episode);

    const result = await post<undefined, FormData, ScriptUploadResult>(
      `${serverUrl}/projects/${projectNo}/scripts/upload`,
      { body: formData }
    );
    return result!!;
  };

  analScript = async (sNo: number): Promise<{}> => {
    const result = await post<undefined, undefined, CommonResponse<{}>>(`${serverUrl}/scripts/${sNo}/analysis`, {});
    return result || {};
  };

  analScriptWithChatGPT = async (sNo: number): Promise<{}> => {
    const result = await post<undefined, undefined, CommonResponse<{}>>(
      `${serverUrl}/scripts/${sNo}/analysis-chat-gpt`,
      {}
    );
    return result.body || {};
  };

  taggingAfterAnal = async (projectNo: number, sNo: number): Promise<{}> => {
    const result = await post<undefined, undefined, CommonResponse<{}>>(
      `${serverUrl}/projects/${projectNo}/scripts/${sNo}/tagging-all`,
      {}
    );
    return result.body || {};
  };
}

const uploadScriptFile = async (projectNo: number, scriptUploadItem: ScriptUploadItem) => {
  const formData = new FormData();
  formData.append('file', scriptUploadItem.file);
  formData.append('episode', scriptUploadItem.episode);

  return SvAPI.post(SvAPI.scriptUploadUrl(projectNo), formData).then((res) => res.data);
};

const uploadScriptFiles = async (projectNo: number, scriptUploadItems: ScriptUploadItem[]) => {
  const formData = new FormData();
  Array.from(scriptUploadItems).forEach((scriptUploadItem) => {
    formData.append('files', scriptUploadItem.file);
    formData.append('episodes', scriptUploadItem.episode);  // 고유 ID 추가
  });
  return SvAPI.post(SvAPI.scriptUploadsUrl(projectNo), formData).then((res) => res.data);
};

const analysisScript = async (scriptNo: number) => {
  return SvAPI.post(SvAPI.scriptAnalysisUrl(scriptNo), {}).then((res) => res.data);
};

const analysisChatGPTScript = async (scriptNo: number) => {
  return SvAPI.post(SvAPI.scriptAnalysisChatGPTUrl(scriptNo), {}).then((res) => res.data);
};

const analysisChatGPTScriptMulti = async (scriptNos: number[]) => {
  return SvAPI.post(SvAPI.scriptAnalysisChatGPTMultiUrl(), { scriptNos }).then((res) => res.data);
};

const tagAllScript = async (projectNo: number, scriptNo: number) => {
  return SvAPI.post(SvAPI.scriptTaggingAllUrl(projectNo, scriptNo), {}).then((res) => res.data);
};

const getChatGptProgress = (scriptNo: number) => {
  return fetch(SvAPI.scriptProgressUrl(scriptNo));
  // return axios.get<Readable>(SvAPI.scriptProgressUrl(scriptNo), { headers: { 'Accept': 'text/event-stream', respnseType: 'stream' }, withCredentials: true });
};

const scriptFileAnalysisAndTaggingAll = async (projectNo: number, scriptNo: number) => {
  await analysisScript(scriptNo);
  await tagAllScript(projectNo, scriptNo);
};

const scriptFileGPTAnalysisAndTaggingAll = async (projectNo: number, scriptNo: number) => {
  await analysisChatGPTScript(scriptNo);
};

export {
  uploadScriptFile,
  uploadScriptFiles,
  analysisScript,
  tagAllScript,
  scriptFileGPTAnalysisAndTaggingAll,
  getChatGptProgress,
  scriptFileAnalysisAndTaggingAll,
  analysisChatGPTScriptMulti,
};
